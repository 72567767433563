import React, { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import MobileMenu from './MobileMenu';
import Footer from './Footer';
import FloatingMenu from './FloatingMenu';
import AccessDeniedMessage from './AccessDeniedMessage';
import useTeachingArchive from '../hooks/useTeachingArchive';
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken } from '../api/authUtils';
import { jwtDecode } from 'jwt-decode'; // Corretto l'importazione
import apiClient from '../api/apiClient';
import {
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import './styles/Resources.css';
import useGetProfile from '../api/profile/useGetProfile';

const Resources = () => {
  const { documents, loading, error, fetchDocuments, uploadDocument } = useTeachingArchive();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [showAccessDenied, setShowAccessDenied] = useState(false);
  const navigate = useNavigate();

  // Utilizza l'hook useGetProfile
  const { profile, profileLoading, profileError } = useGetProfile();
  const subscriptionType = profile?.subscription_type || 'free';

  useEffect(() => {
    const checkAuthStatus = async () => {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (accessToken) {
        try {
          const decodedAccessToken = jwtDecode(accessToken);
          const currentTime = Date.now() / 1000;

          if (decodedAccessToken.exp && decodedAccessToken.exp > currentTime) {
            setIsLoggedIn(true);
          } else if (refreshToken) {
            try {
              const response = await apiClient.post('/api/accounts/token/refresh/', {
                refresh: refreshToken,
              });

              if (response.status === 200) {
                const data = response.data;
                setAccessToken(data.access);
                setIsLoggedIn(true);
              } else {
                removeTokens();
                setIsLoggedIn(false);
              }
            } catch (error) {
              console.error('Errore durante il refresh del token:', error);
              removeTokens();
              setIsLoggedIn(false);
            }
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante la decodifica del token di accesso:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else if (refreshToken) {
        try {
          const response = await apiClient.post('/api/accounts/token/refresh/', {
            refresh: refreshToken,
          });

          if (response.status === 200) {
            const data = response.data;
            setAccessToken(data.access);
            setIsLoggedIn(true);
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante il refresh del token:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const handleDocumentClick = (doc) => {
    if (doc.premium) {
      if (isLoggedIn && (subscriptionType === 'monthly' || subscriptionType === 'annual')) {
        navigate(`/documents/detail/${encodeURIComponent(doc.document_name)}`);
      } else {
        setShowAccessDenied(true);
      }
    } else {
      navigate(`/documents/detail/${encodeURIComponent(doc.document_name)}`);
    }
  };

  // Ordinamento dei documenti: premium prima, poi per data di upload decrescente
  const sortedDocuments = useMemo(() => {
    return [...documents].sort((a, b) => {
      if (a.premium === b.premium) {
        return new Date(b.uploaded_at) - new Date(a.uploaded_at);
      }
      return a.premium ? -1 : 1;
    });
  }, [documents]);

  return (
    <Box className="resourcesPage_MainContainer">
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />

      {mobileMenuOpen && (
        <MobileMenu 
          isLoggedIn={isLoggedIn} 
          setIsLoggedIn={setIsLoggedIn} 
          setMobileMenuOpen={setMobileMenuOpen} 
        />
      )}

      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            removeTokens();
            setIsLoggedIn(false);
          }}
        />
      )}

      <Box className="resourcesPage_ContentContainer">
        <main className="resourcesPage_MainContent">
          <motion.section
            className="resourcesHeader_UniqueSection"
            initial="hidden"
            animate="visible"
            variants={sectionVariants}
          >
            <Typography variant="h4" component="h1" gutterBottom>
              Risorse Didattiche Minerva
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Accedi ad attività didattiche pronte create da Minerva AI
            </Typography>
          </motion.section>

          <motion.section
            className="resourcesList_UniqueSection"
            initial="hidden"
            animate="visible"
            variants={sectionVariants}
          >
            {loading || profileLoading ? (
              <Box className="resourcesList_LoadingBox">
                <CircularProgress />
              </Box>
            ) : error || profileError ? (
              <Typography color="error">Si è verificato un errore durante il caricamento delle risorse.</Typography>
            ) : (
              <Box className="categoriesContainer_UniqueWrapper">
                <Box className="categoriesGrid_UniqueDisplay">
                  {sortedDocuments.map((doc) => (
                    <Box className="categoryItem_UniqueBox" key={doc.id}>
                      <Box className="categoryTitleContainer">
                        {doc.premium && (
                          <img
                            src="/resources/premium_resources.png"
                            alt="Premium"
                            className="premiumIcon"
                          />
                        )}
                        <Typography variant="body2" className="categoryTitle_UniqueText">
                          {doc.document_name}
                        </Typography>
                      </Box>
                      <Box
                        className="documentCategory_UniqueBox"
                        onClick={() => handleDocumentClick(doc)}
                        style={{ cursor: 'pointer' }}
                      >
                        {doc.thumbnail ? (
                          <img
                            src={doc.thumbnail}
                            alt={`${doc.document_name} Thumbnail`}
                            className="thumbnailImage_UniqueImg"
                          />
                        ) : (
                          <Typography variant="body2" color="white">
                            Anteprima non disponibile
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </motion.section>
        </main>
      </Box>

      <Footer />

      {/* Snackbar per le notifiche */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Componente per il messaggio di accesso negato */}
      {showAccessDenied && (
        <AccessDeniedMessage onClose={() => setShowAccessDenied(false)} />
      )}
    </Box>
  );
};

export default Resources;