// AgentStartMobile.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  Dialog,
  Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { keyframes } from '@emotion/react';

// Definizione degli agenti
const agents = [
  {
    name: 'Metodologie',
    description: 'Scopri metodologie, con consigli su risorse e spiegazioni.',
    image: '/people/metodologie.png',
    agentKey: 'metodologie',
  },
  {
    name: 'Attività',
    description: 'Crea attività didattiche innovative per la tua classe.',
    image: '/people/attività.png',
    agentKey: 'attività',
  },
  {
    name: 'Quiz',
    description: 'Genera quiz stimolanti per diversi livelli di apprendimento.',
    image: '/people/quiz.png',
    agentKey: 'quiz',
  },
  {
    name: 'Laboratori e progetti',
    description: 'Perfetto per ideare e sviluppare laboratori o progetti stimolanti',
    image: '/people/laboratorio.png',
    agentKey: 'laboratori',
  },
  {
    name: 'Montessori',
    description: 'Scopri il Metodo Montessori per un apprendimento personalizzato.',
    image: '/people/montessori.png',
    agentKey: 'montessori',
  },
  {
    name: 'Malaguzzi',
    description: 'Approfondisci il Metodo Reggio Emilia per lo sviluppo creativo.',
    image: '/people/malaguzzi.png',
    agentKey: 'malaguzzi',
  },
  {
    name: 'Scrittura',
    description: 'Assistenza nella redazione di: UDA, Programmazioni, verbali e altro.',
    image: '/people/scrittura.png',
    agentKey: 'scrittura',
  },
  {
    name: 'Minerva',
    description: 'Un’AI con una conoscenza approfondita del sistema scolastico italiano, delle normative ministeriali e delle Indicazioni Nazionali,',
    image: '/people/minerva.png',
    agentKey: 'minerva',
  },
  {
    name: 'ImmaginAI',
    description: 'Crea immagini generate con AI.',
    image: '/people/immaginai.png',
    agentKey: 'immaginai',
  },
];

// Animazione per i tre puntini
const loadingAnimation = keyframes`
  0% { opacity: 0.2; transform: translateY(0); }
  20% { opacity: 1; transform: translateY(-5px); }
  100% { opacity: 0.2; transform: translateY(0); }
`;

// Componente LoadingIndicator con i tre puntini animati
const LoadingIndicator = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0s',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0.2s',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0.4s',
        }}
      />
    </Box>
  </Box>
);

// Funzione per precaricare le immagini
const preloadImages = (imageUrls) => {
  return Promise.all(
    imageUrls.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        })
    )
  );
};

// Transizione per il Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AgentStartMobile = ({ open, onClose, handleNewChat }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (open) {
      // Raccogli tutti gli URL delle immagini
      const imageUrls = agents.map((agent) => agent.image);

      // Inizia il caricamento delle immagini
      preloadImages(imageUrls)
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Errore nel caricamento delle immagini:', error);
          setIsLoading(false); // Anche in caso di errore, prosegui per non bloccare l'interfaccia
        });
    } else {
      // Reset dello stato di caricamento quando il componente si chiude
      setIsLoading(true);
    }
  }, [open]);

  if (!open) return null;

  // Durante il caricamento, mostra solo l'animazione
  if (isLoading) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Assicura che l'animazione sia centrata verticalmente
          },
        }}
      >
        <LoadingIndicator />
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          backgroundColor: '#FFFFFF',
          padding: '1rem',
          width: { xs: '90%', sm: '80%', md: '60%' }, // Responsive width
          maxHeight: '80vh', // Limita l'altezza massima
          overflowY: 'auto', // Abilita lo scroll verticale se necessario
          borderRadius: '16px',
        },
      }}
    >
      {/* Header con titolo e pulsante di chiusura */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1rem',
          position: 'relative', // Per posizionare l'IconButton correttamente
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, flexGrow: 1, textAlign: 'center' }}
        >
          Scegli l'esperto di cui hai bisogno
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 10 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Lista degli agenti */}
      <Grid container spacing={1} justifyContent="center">
        {agents.map((agent) => (
          <Grid item xs={4} sm={3} key={agent.agentKey}>
            <Paper
              elevation={0} // Look più moderno senza bordi
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0.5rem',
                borderRadius: '12px',
                backgroundColor: '#f9f9f9', // Colore chiaro di sfondo
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                transition: 'background-color 0.3s, transform 0.3s',
                height: '130px', // Altezza leggermente aumentata per le descrizioni
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                '&:hover': {
                  backgroundColor: '#f0f0f0', // Effetto hover delicato
                  transform: 'scale(1.02)', // Leggera ingranditura al hover
                },
                '&:active': {
                  boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.2)', // Feedback al tocco
                },
              }}
              onClick={() => {
                handleNewChat(agent.agentKey);
                onClose();
              }}
            >
              {/* Immagine */}
              <Box
                component="img"
                src={agent.image}
                alt={agent.name}
                sx={{
                  width: '40px',
                  height: '40px',
                  marginBottom: '0.5rem',
                  borderRadius: '50%', // Forma circolare per un look moderno
                  backgroundColor: '#fff',
                  padding: '0.2rem', // Per evidenziare l'immagine
                }}
              />

              {/* Titolo */}
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  textAlign: 'center',
                  color: '#333',
                }}
              >
                {agent.name}
              </Typography>

              {/* Descrizione */}
              <Typography
                variant="body2"
                sx={{
                  fontSize: '0.75rem',
                  textAlign: 'center',
                  color: '#666',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2, // Limita a 2 righe
                  WebkitBoxOrient: 'vertical',
                  marginTop: '0.25rem',
                }}
              >
                {agent.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Dialog>
  );
};

export default AgentStartMobile;
