// ChatMessages.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  useMediaQuery,
  Typography,
  CircularProgress,
  Card,
  CardActionArea,
  CardContent,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';

import { keyframes } from '@emotion/react';
import { Document, Packer, Paragraph, TextRun, HeadingLevel } from 'docx';
import { saveAs } from 'file-saver';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { marked } from 'marked';
import he from 'he';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

import MediaDisplay from './MediaDisplay';
import ActionButtons from './ActionButtons';
import MediaTypeDialog from './MediaTypeDialog';
import VideoComponent from '../VideoComponent';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const typing = keyframes`
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
`;

const loadingAnimation = keyframes`
  0% { opacity: 0.2; transform: translateY(0); }
  20% { opacity: 1; transform: translateY(-5px); }
  100% { opacity: 0.2; transform: translateY(0); }
`;

const LoadingIndicator = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    <CircularProgress />
  </Box>
);

const ChatMessages = ({
  messages,
  loading,
  containerRef,
  chatTitle,
  selectedOption,
  handleMediaSearch,
  showVideo, // Accept the new prop
  agentExamples, // Receive the examples
  onExampleClick, // Receive the click handler
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  // State for the media type dialog
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [selectedMediaType, setSelectedMediaType] = useState('image');
  const [currentMessage, setCurrentMessage] = useState(null);
  const [imageLoadingStatus, setImageLoadingStatus] = useState({});

  // Function to open the media type dialog
  const openMediaDialog = (message) => {
    setCurrentMessage(message);
    setMediaDialogOpen(true);
  };

  // Function to confirm media type selection and initiate search
  const handleMediaSearchConfirm = (mediaType) => {
    setMediaDialogOpen(false);
    setSelectedMediaType(mediaType);
    handleMediaSearch(currentMessage, mediaType);
  };

  // Function to copy text to clipboard
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('Testo copiato negli appunti!');
      })
      .catch((err) => {
        console.error('Errore nella copia: ', err);
      });
  };

  // Function to sanitize chat title for file names
  const sanitizeFileName = (name) => {
    return name.replace(/[^a-z0-9_\-]/gi, '_');
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  // Function to download message as PDF
  const handleDownloadPDF = (markdownText) => {
    const safeTitle = sanitizeFileName(chatTitle || 'chat');

    // Convert Markdown to HTML
    const htmlContent = marked(markdownText);

    // Convert HTML to pdfmake format
    const pdfContent = htmlToPdfmake(htmlContent);

    const docDefinition = {
      content: pdfContent,
      defaultStyle: {
        font: 'Roboto',
      },
    };

    if (isIOS) {
      pdfMake.createPdf(docDefinition).open();
    } else {
      pdfMake.createPdf(docDefinition).download(`${safeTitle}.pdf`);
    }
  };

  // Function to download message as Word document (.docx)
  const handleDownload = (markdownText) => {
    const safeTitle = sanitizeFileName(chatTitle || 'chat');

    // Function to convert markdown to docx elements
    const convertMarkdownToDocx = (markdown) => {
      const docElements = [];

      // Create lexer
      const lexer = new marked.Lexer();
      const tokens = lexer.lex(markdown);

      tokens.forEach((token) => {
        switch (token.type) {
          case 'heading':
            docElements.push(
              new Paragraph({
                text: he.decode(token.text),
                heading: HeadingLevel[`HEADING_${token.depth}`],
                spacing: { after: 200 - token.depth * 20 },
              })
            );
            break;
          case 'paragraph':
            const runs = [];
            const inlineTokens = lexer.inlineTokens(token.text);

            inlineTokens.forEach((inlineToken) => {
              if (!inlineToken.type) {
                console.error('Token con tipo undefined:', inlineToken);
                return;
              }
              const decodedText = he.decode(inlineToken.text || '');
              switch (inlineToken.type) {
                case 'text':
                  runs.push(new TextRun({ text: decodedText }));
                  break;
                case 'strong':
                  runs.push(new TextRun({ text: decodedText, bold: true }));
                  break;
                case 'em':
                  runs.push(new TextRun({ text: decodedText, italics: true }));
                  break;
                case 'codespan':
                  runs.push(new TextRun({ text: decodedText, font: 'Courier New' }));
                  break;
                default:
                  runs.push(new TextRun({ text: he.decode(inlineToken.raw || '') }));
                  break;
              }
            });

            docElements.push(
              new Paragraph({
                children: runs,
                spacing: { after: 120 },
              })
            );
            break;
          case 'list':
            token.items.forEach((item) => {
              const itemRuns = [];
              const itemInlineTokens = lexer.inlineTokens(item.text);

              itemInlineTokens.forEach((inlineToken) => {
                if (!inlineToken.type) {
                  console.error('Token con tipo undefined:', inlineToken);
                  return;
                }
                const decodedText = he.decode(inlineToken.text || '');
                switch (inlineToken.type) {
                  case 'text':
                    itemRuns.push(new TextRun({ text: decodedText }));
                    break;
                  case 'strong':
                    itemRuns.push(new TextRun({ text: decodedText, bold: true }));
                    break;
                  case 'em':
                    itemRuns.push(new TextRun({ text: decodedText, italics: true }));
                    break;
                  case 'codespan':
                    itemRuns.push(new TextRun({ text: decodedText, font: 'Courier New' }));
                    break;
                  default:
                    itemRuns.push(new TextRun({ text: he.decode(inlineToken.raw || '') }));
                    break;
                }
              });

              docElements.push(
                new Paragraph({
                  children: itemRuns,
                  bullet: {
                    level: item.depth || 0,
                  },
                  spacing: { after: 120 },
                })
              );
            });
            break;
          case 'space':
            docElements.push(new Paragraph({ text: '', spacing: { after: 120 } }));
            break;
          default:
            break;
        }
      });

      return docElements;
    };

    // Create the document
    const doc = new Document({
      styles: {
        paragraphStyles: [
          {
            id: 'default',
            name: 'Default Style',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              font: 'Calibri',
              size: 22,
            },
          },
        ],
      },
      sections: [
        {
          properties: {},
          children: convertMarkdownToDocx(markdownText),
        },
      ],
    });

    // Generate and save the document blob
    if (isIOS) {
      Packer.toBase64String(doc)
        .then((base64String) => {
          const url =
            'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' +
            base64String;
          const link = document.createElement('a');
          link.href = url;
          link.download = `${safeTitle}.docx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error('Errore nella generazione del documento: ', err);
        });
    } else {
      Packer.toBlob(doc)
        .then((blob) => {
          saveAs(blob, `${safeTitle}.docx`);
        })
        .catch((err) => {
          console.error('Errore nella generazione del documento: ', err);
        });
    }
  };

  // Function to get attachment icon based on type
  const getAttachmentIcon = (attachment) => {
    const fileExtension = attachment.file_name.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'pdf':
        return <PictureAsPdfIcon color="error" />;
      case 'doc':
      case 'docx':
        return <InsertDriveFileIcon style={{ color: '#3F51B5' }} />;
      case 'txt':
        return <InsertDriveFileIcon style={{ color: '#4CAF50' }} />;
      default:
        return <AttachFileIcon />;
    }
  };

  // Clean up object URLs to prevent memory leaks
  useEffect(() => {
    return () => {
      Object.keys(imageLoadingStatus).forEach((imageKey) => {
        if (
          imageLoadingStatus[imageKey] &&
          imageLoadingStatus[imageKey].startsWith('blob:')
        ) {
          URL.revokeObjectURL(imageLoadingStatus[imageKey]);
        }
      });
    };
  }, [imageLoadingStatus]);

  return (
    <Box
      ref={containerRef}
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        padding: isMobile ? '1rem' : '2rem',
        overflowWrap: 'break-word',
        position: 'relative',
        marginTop: isMobile ? '0' : '-1.5rem',
      }}
    >
      {/* MediaTypeDialog component */}
      <MediaTypeDialog
        open={mediaDialogOpen}
        onClose={() => setMediaDialogOpen(false)}
        selectedMediaType={selectedMediaType}
        onConfirm={handleMediaSearchConfirm}
      />

      {/* Main content */}
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {messages.map((message, index) => {
            // Determine if this is the initial message
            const isInitialMessage = message.isInitial && message.sender === 'assistant';

            // Determine if the agent is 'immaginai' and the sender is 'assistant'
            const isImmaginAI = selectedOption === 'immaginai' && message.sender === 'assistant';

            // Find the image URL if present
            let imageUrl = '';
            if (message.sender === 'assistant' && message.attachments && message.attachments.length > 0) {
              const imageAttachment = message.attachments.find((att) => {
                const ext = att.file_name.split('.').pop().toLowerCase();
                return ['jpg', 'jpeg', 'png', 'gif'].includes(ext);
              });
              if (imageAttachment) {
                imageUrl = imageAttachment.file_url || '';
                if (!imageUrl && imageAttachment.file) {
                  imageUrl = URL.createObjectURL(imageAttachment.file);
                }
              }
            }

            return (
              <Box
                key={index}
                sx={{
                  marginBottom: isMobile ? '1rem' : '2.5rem',
                  position: 'relative',
                  marginTop: index === 0 ? '2rem' : '0',
                }}
              >
                {message.sender === 'assistant' && message.isTyping ? (
                  // Typing indicator
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      textAlign: 'left',
                    }}
                  >
                    <Box
                      sx={{
                        width: isMobile ? '2rem' : '2.8125rem',
                        height: isMobile ? '2rem' : '2.8125rem',
                        margin: '0 0.625rem 0 0',
                        backgroundColor: '#DF4634',
                        borderRadius: '1.875rem',
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0.625rem 0.9375rem',
                        borderRadius: '1.875rem',
                        boxShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
                        backgroundColor: '#ffffff',
                        maxWidth: '83.5%',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 400,
                        fontSize: '0.875rem',
                        lineHeight: '1rem',
                        padding: isMobile ? '1rem' : '1.5rem',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: '#DF4634',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            margin: '0 2px',
                            animation: `${typing} 1s infinite`,
                            animationDelay: '0s',
                          }}
                        />
                        <Box
                          sx={{
                            backgroundColor: '#DF4634',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            margin: '0 2px',
                            animation: `${typing} 1s infinite`,
                            animationDelay: '0.2s',
                          }}
                        />
                        <Box
                          sx={{
                            backgroundColor: '#DF4634',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            margin: '0 2px',
                            animation: `${typing} 1s infinite`,
                            animationDelay: '0.4s',
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <>
                    {/* User or assistant message */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: message.sender === 'user' ? 'row-reverse' : 'row',
                        textAlign: 'left',
                      }}
                    >
                      {/* Avatar */}
                      {message.sender === 'assistant' &&
                      (selectedOption === 'montessori' || selectedOption === 'malaguzzi') ? (
                        <Box
                          component="img"
                          src={`/people/${selectedOption}.png`}
                          alt={
                            selectedOption === 'montessori'
                              ? 'Maria Montessori'
                              : 'Loris Malaguzzi'
                          }
                          sx={{
                            width: isMobile ? '2rem' : '2.8125rem',
                            height: isMobile ? '2rem' : '2.8125rem',
                            margin: isMobile ? '0 0.5rem 0 0' : '0 0.625rem 0 0',
                            borderRadius: '50%',
                          }}
                        />
                      ) : (
                        <Box
                          component="img"
                          src={
                            message.sender === 'user'
                              ? '/menu_icon/profile.png'
                              : '/chat_icon/minerva_robot.png'
                          }
                          sx={{
                            width: isMobile ? '2rem' : '2.8125rem',
                            height: isMobile ? '2rem' : '2.8125rem',
                            margin:
                              message.sender === 'user'
                                ? isMobile
                                  ? '0 0 0 0.5rem'
                                  : '0 0 0 0.625rem'
                                : isMobile
                                ? '0 0.5rem 0 0'
                                : '0 0.625rem 0 0',
                            backgroundColor:
                              message.sender === 'user' ? '#000000' : '#DF4634',
                            borderRadius: '1.875rem',
                          }}
                        />
                      )}
                      {/* Message content */}
                      <Box
                        sx={{
                          position: 'relative',
                          paddingX: isMobile ? '2rem' : '2rem',
                          paddingY: isMobile ? '2rem' : '2rem',
                          borderRadius: '1.875rem',
                          boxShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
                          whiteSpace: 'pre-wrap',
                          backgroundColor: '#ffffff',
                          color: '#000000',
                          display: 'inline-block',
                          maxWidth: message.sender === 'user' ? '70%' : '83.5%',
                          fontFamily: 'Inter, sans-serif',
                          fontWeight: 400,
                          fontSize: isMobile ? '0.85rem' : '0.875rem',
                          lineHeight: '1rem',
                        }}
                      >
                        <ReactMarkdown children={message.text} />

                        {isInitialMessage && showVideo && selectedOption === 'attività' && (
                          <Box sx={{ marginTop: '1rem' }}>
                            <video
                              src={`/video/${selectedOption}.mp4`}
                              controls
                              style={{ width: '100%', borderRadius: '0.5rem' }}
                            />
                          </Box>
                        )}

                        {/* Display examples under the welcome message */}
                        {isInitialMessage && agentExamples && (
                          <Box
                            sx={{
                              marginTop: '1rem',
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap', // Permette agli elementi di andare a capo
                              gap: '1rem',
                              justifyContent: 'center', // Centra gli elementi all'interno del contenitore
                            }}
                          >
                            {agentExamples.map((example, idx) => (
                              <Card
                                key={idx}
                                variant="outlined"
                                sx={{
                                  backgroundColor: '#fff',
                                  border: '1px solid #e0e0e0',
                                  borderRadius: '0.5rem',
                                  boxShadow: 'none',
                                  padding: '0.5rem 1rem',
                                  cursor: 'pointer',
                                  transition: 'background-color 0.3s',
                                  minWidth: '150px',
                                  flex: '1 1 150px', // Permette alle carte di crescere e restringersi
                                  maxWidth: '280px', // Limita la larghezza massima per mantenere un aspetto uniforme
                                  '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                  },
                                }}
                                onClick={() => onExampleClick(example)}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: '0.875rem',
                                    color: '#333',
                                    textAlign: 'center',
                                  }}
                                >
                                  {example}
                                </Typography>
                              </Card>
                            ))}
                          </Box>
                        )}

                        {message.attachments && message.attachments.length > 0 && (
                          <Box sx={{ marginTop: '0.5rem', width: '100%' }}>
                            {message.attachments.map((attachment, idx) => {
                              const fileExtension = attachment.file_name
                                .split('.')
                                .pop()
                                .toLowerCase();
                              if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
                                // If the attachment is an image
                                let imageSrc = attachment.file_url;
                                if (!imageSrc && attachment.file) {
                                  // If file_url is not available, but file is, create a local URL
                                  imageSrc = URL.createObjectURL(attachment.file);
                                }

                                if (!imageSrc) {
                                  return (
                                    <Box
                                      key={idx}
                                      sx={{
                                        marginBottom: '0.5rem',
                                        textAlign: 'left',
                                      }}
                                    >
                                      <Typography variant="body2" color="textSecondary">
                                        Immagine in caricamento...
                                      </Typography>
                                    </Box>
                                  );
                                }

                                // Set image width based on sender
                                const imageWidth = isMobile
                                  ? '95%'
                                  : message.sender === 'user'
                                  ? '10rem'
                                  : '20rem';
                                // Generate a unique key for the image
                                const imageKey = imageSrc + idx;

                                // Check loading status
                                const isLoading = imageLoadingStatus[imageKey] !== false;

                                return (
                                  <Box
                                    key={idx}
                                    sx={{
                                      marginBottom: '0.5rem',
                                      textAlign: 'left',
                                      position: 'relative',
                                      width: imageWidth,
                                      height: 'auto',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {isLoading && (
                                      <CircularProgress sx={{ color: '#E57373' }} />
                                    )}
                                    <img
                                      src={imageSrc}
                                      alt={attachment.file_name}
                                      style={{
                                        width: imageWidth,
                                        height: 'auto',
                                        borderRadius: '0.5rem',
                                        display: isLoading ? 'none' : 'block',
                                      }}
                                      onLoad={() => {
                                        setImageLoadingStatus((prevState) => ({
                                          ...prevState,
                                          [imageKey]: false,
                                        }));
                                      }}
                                      onError={() => {
                                        setImageLoadingStatus((prevState) => ({
                                          ...prevState,
                                          [imageKey]: false,
                                        }));
                                      }}
                                    />
                                  </Box>
                                );
                              } else {
                                // Otherwise, show file icon
                                return (
                                  <Box
                                    key={idx}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '0.5rem',
                                      backgroundColor: '#f5f5f5',
                                      padding: '0.5rem 1rem',
                                      borderRadius: '0.5rem',
                                    }}
                                  >
                                    {getAttachmentIcon(attachment)}
                                    <Typography
                                      variant="body2"
                                      sx={{ marginLeft: '0.5rem', flexGrow: 1 }}
                                    >
                                      {attachment.file_name}
                                    </Typography>
                                  </Box>
                                );
                              }
                            })}
                          </Box>
                        )}
                      </Box>
                    </Box>

                    {/* Action buttons for assistant messages */}
                    {message.sender === 'assistant' &&
                      !message.isTyping &&
                      !message.isInitial && (
                        <ActionButtons
                          messageText={message.text}
                          onCopy={handleCopy}
                          onDownloadWord={handleDownload}
                          onDownloadPDF={handleDownloadPDF}
                          onSearchMaterial={() => openMediaDialog(message)}
                          isMobile={isMobile}
                          disableSearch={message.imagesLoading || message.images}
                          isImmaginAI={isImmaginAI}
                          imageUrl={imageUrl}
                        />
                      )}

                    {/* Show loading of images/videos/PDF */}
                    {message.imagesLoading && (
                      <Box sx={{ marginTop: '1rem', textAlign: 'center' }}>
                        <CircularProgress sx={{ color: '#E57373' }} />
                        <Typography variant="body2" color="textSecondary">
                          Ricerca in corso...
                        </Typography>
                      </Box>
                    )}

                    {/* Display available media using MediaDisplay component */}
                    <MediaDisplay
                      media={message.media}
                      selectedMediaType={selectedMediaType}
                    />
                  </>
                )}
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default ChatMessages;