// src/api/subscription/useCheckFreeTrialStatus.js
import { useState, useEffect } from 'react';
import apiClient from '../apiClient';

const useCheckFreeTrialStatus = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [freeActive, setFreeActive] = useState(false);

  const checkFreeTrialStatus = async () => {
    setLoading(true);
    setError(null);
    setFreeActive(false);
    try {
      const response = await apiClient.post('/api/accounts/check-free-trial/', {
        email: localStorage.getItem('email'), // Assicurati che l'email sia memorizzata localmente
      });
      setFreeActive(response.data.free_active);
    } catch (err) {
      setError(err.response?.data?.error || 'Errore nel controllo dello stato della prova gratuita.');
    } finally {
      setLoading(false);
    }
  };

  // Effettua il controllo una sola volta quando il componente viene montato
  useEffect(() => {
    checkFreeTrialStatus();
  }, []);

  return { loading, error, freeActive };
};

export default useCheckFreeTrialStatus;