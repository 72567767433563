// src/components/DocumentDetail.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from './Header';
import MobileMenu from './MobileMenu';
import Footer from './Footer';
import FloatingMenu from './FloatingMenu';
import useTeachingDocument from '../hooks/useTeachingDocument';
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken } from '../api/authUtils';
import { jwtDecode } from 'jwt-decode';
import apiClient from '../api/apiClient';
import {
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import './styles/DocumentDetail.css'; // Assicurati che il percorso sia corretto

const DocumentDetail = () => {
  const { documentName } = useParams();
  const { document, loading, error, fetchDocumentByName } = useTeachingDocument();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false); // Nuovo stato per abbonamento
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthStatus = async () => {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (accessToken) {
        try {
          const decodedAccessToken = jwtDecode(accessToken);
          const currentTime = Date.now() / 1000;

          if (decodedAccessToken.exp && decodedAccessToken.exp > currentTime) {
            setIsLoggedIn(true);
            setHasSubscription(decodedAccessToken.has_subscription);
          } else if (refreshToken) {
            try {
              const response = await apiClient.post('/api/accounts/token/refresh/', {
                refresh: refreshToken,
              });

              if (response.status === 200) {
                const data = response.data;
                setAccessToken(data.access);
                const decodedNewAccessToken = jwtDecode(data.access);
                setIsLoggedIn(true);
                setHasSubscription(decodedNewAccessToken.has_subscription);
              } else {
                removeTokens();
                setIsLoggedIn(false);
                setHasSubscription(false);
                navigate('/abbonamento');
              }
            } catch (error) {
              console.error('Errore durante il refresh del token:', error);
              removeTokens();
              setIsLoggedIn(false);
              setHasSubscription(false);
              navigate('/abbonamento');
            }
          } else {
            removeTokens();
            setIsLoggedIn(false);
            setHasSubscription(false);
            navigate('/abbonamento');
          }
        } catch (error) {
          console.error('Errore durante la decodifica del token di accesso:', error);
          removeTokens();
          setIsLoggedIn(false);
          setHasSubscription(false);
          navigate('/abbonamento');
        }
      } else if (refreshToken) {
        try {
          const response = await apiClient.post('/api/accounts/token/refresh/', {
            refresh: refreshToken,
          });

          if (response.status === 200) {
            const data = response.data;
            setAccessToken(data.access);
            const decodedNewAccessToken = jwtDecode(data.access);
            setIsLoggedIn(true);
            setHasSubscription(decodedNewAccessToken.has_subscription);
          } else {
            removeTokens();
            setIsLoggedIn(false);
            setHasSubscription(false);
            navigate('/abbonamento');
          }
        } catch (error) {
          console.error('Errore durante il refresh del token:', error);
          removeTokens();
          setIsLoggedIn(false);
          setHasSubscription(false);
          navigate('/abbonamento');
        }
      } else {
        setIsLoggedIn(false);
        setHasSubscription(false);
        navigate('/abbonamento');
      }
    };

    checkAuthStatus();
  }, [navigate]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (documentName) {
      fetchDocumentByName(documentName).catch((err) => {
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          navigate('/abbonamento');
        }
      });
    }
  }, [documentName, fetchDocumentByName, navigate]);

  return (
    <Box className="documentDetail_MainContainer">
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />

      {mobileMenuOpen && (
        <MobileMenu isLoggedIn={isLoggedIn} setMobileMenuOpen={setMobileMenuOpen} />
      )}

      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            removeTokens();
            setIsLoggedIn(false);
            setHasSubscription(false);
          }}
        />
      )}

      <Box className="documentDetail_ContentContainer">
        <main className="documentDetail_MainContent">
          {loading ? (
            <Box className="documentDetail_LoadingBox">
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error">Errore nel caricamento del documento: {error.message}</Typography>
          ) : document ? (
            <Box className="documentDetail_DisplayWrapper">
              <Box className="documentDetail_IframeContainer">
                <iframe
                  src={document.file}
                  title={document.document_name}
                  className="documentDetail_Iframe"
                  frameBorder="0"
                />
              </Box>
              <Box className="documentDetail_InfoContainer">
                <Typography variant="h5" gutterBottom>
                  {document.document_name}
                </Typography>
                {document.subtitle && (
                  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    {document.subtitle}
                  </Typography>
                )}
                {document.description && (
                  <Typography variant="body1" gutterBottom>
                    {document.description}
                  </Typography>
                )}
                <Box mt={2}>
                  <a
                    href={document.file}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="downloadButton"
                  >
                    Scarica il documento
                  </a>
                </Box>
                <Typography variant="caption" color="textSecondary" mt={1}>
                  Caricato il: {new Date(document.uploaded_at).toLocaleString()}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Typography>Nessun documento selezionato.</Typography>
          )}
        </main>
      </Box>

      <Footer />

      {/* Snackbar per le notifiche */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DocumentDetail;