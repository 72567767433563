// agentExamples.js

export const agentExamples = {
    quiz: [
      "Crea un quiz di matematica per classe quinta primaria sulle frazioni.",
      "Vorrei un quiz stile invalsi di italiano per classe terza primaria",
    ],
    laboratori: [
      "Mi serve un laboratorio di scienze per una seconda primaria usando materiali riciclati.",
      "Proponi un'attività laboratoriale su circuiti elettrici per la classe prima media",
    ],
    attività: [
      "Crea un'attività didattica sul ciclo dell'acqua per una quarta primaria",
      "Come posso insegnare la fotosintesi in modo innovativo per una seconda media?",
    ],
    metodologie: [
      "Quali metodologie innovative posso usare per insegnare storia?",
      "Quale metodo posso applicare per instaurare un rapporto empatico con un bambino nello spettro autistico?",
    ],
    montessori: [
      "Come applicare il metodo Montessori per insegnare geometria per una classe quarta?",
      "Suggerimenti per attività Montessori per bambini di 5 anni",
    ],
    malaguzzi: [
      "Idee per un progetto educativo seguendo l'approccio di Reggio Emilia per bambini di 6 anni",
      "Come incoraggiare l'espressione artistica secondo Malaguzzi in classe prima",
    ],
    scrittura: [
      "Aiutami a scrivere un verbale per la riunione dei genitori",
      "Ho bisogno di una UDA per italiano",
    ],
    immaginai: [
      "Creami un immagine che rappresenta il ciclo dell'acqua",
      "Crea un'immagine di un supereroe da colorare per bambini",
    ],
    minerva: [
      "Quali sono le Indicazioni Nazionali per il curricolo della scuola primaria?",
      "Puoi spiegarmi cosa prevede il PTOF e come redigerlo?",
    ],
  };