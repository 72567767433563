// ActionButtons.js
import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';

// Immagini per i pulsanti
const copyImage = '/chat_icon/copy.png';
const docsImage = '/chat_icon/docs.png';
const pdfImage = '/chat_icon/pdf.png';
const downloadImgButton = '/chat_icon/download_img.png'; // Nuova immagine per il pulsante di download

const ActionButtons = ({
  messageText,
  onCopy,
  onDownloadWord,
  onDownloadPDF,
  onSearchMaterial,
  isMobile,
  disableSearch,
  isImmaginAI, // Nuova proprietà
  imageUrl, // URL dell'immagine da scaricare
}) => {
  // Funzione per gestire il download dell'immagine
  const handleImageDownload = async () => {
    try {
      const response = await fetch(imageUrl, {
        mode: 'cors', // Assicurati che il server consenta CORS
      });
      if (!response.ok) {
        throw new Error('Impossibile scaricare l\'immagine.');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      // Puoi personalizzare il nome del file come preferisci
      a.download = `generated_image_${Date.now()}.png`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Errore durante il download dell\'immagine:', error);
      alert('Si è verificato un errore durante il download dell\'immagine.');
    }
  };

  if (isImmaginAI) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '0.5rem',
          marginLeft: isMobile ? '1rem' : '3.5rem',
        }}
      >
        {imageUrl ? (
          <Tooltip title="Scarica Immagine">
            <IconButton
              onClick={handleImageDownload}
              aria-label="Download Image"
              sx={{
                backgroundColor: '#DF4634',
                borderRadius: '50%',
                padding: '0.5rem',
                '&:hover': {
                  backgroundColor: '#E57373',
                },
              }}
            >
              <img
                src={downloadImgButton}
                alt="Download"
                style={{ width: '18px', height: '18px' }}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Immagine non disponibile">
            <IconButton
              disabled
              aria-label="Download Image Not Available"
              sx={{
                backgroundColor: '#BDBDBD',
                borderRadius: '50%',
                padding: '0.5rem',
              }}
            >
              <img
                src={downloadImgButton}
                alt="Download Not Available"
                style={{ width: '18px', height: '18px', opacity: 0.5 }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '0.5rem',
        marginLeft: isMobile ? '1rem' : '3.5rem',
      }}
    >
      {/* Contenitore grigio per "Copia", "Scarica come Word" e "Scarica PDF" */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F3F6FB', // Sfondo grigio chiaro
          borderRadius: '1.25rem', // Bordi arrotondati
          padding: '0.28rem 0.5rem', // Spaziatura interna
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)', // Leggera ombreggiatura
        }}
      >
        {/* Pulsante Copia */}
        <Tooltip title="Copia Testo">
          <IconButton
            onClick={() => onCopy(messageText)}
            aria-label="Copy"
          >
            <img
              src={copyImage}
              alt="Copy"
              style={{ width: '18px', height: '18px' }}
            />
          </IconButton>
        </Tooltip>

        {/* Pulsante Scarica come Word */}
        <Tooltip title="Scarica come Word">
          <IconButton
            onClick={() => onDownloadWord(messageText)}
            aria-label="Download Docs"
          >
            <img
              src={docsImage}
              alt="Docs"
              style={{ width: '18px', height: '18px' }}
            />
          </IconButton>
        </Tooltip>

        {/* Pulsante Scarica PDF */}
        <Tooltip title="Scarica PDF">
          <IconButton
            onClick={() => onDownloadPDF(messageText)}
            aria-label="PDF"
          >
            <img
              src={pdfImage}
              alt="Pdf"
              style={{ width: '18px', height: '18px' }}
            />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Pulsante "Cerca Materiale" separato */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#DF4634',
          borderRadius: '1.875rem',
          padding: '0.6rem 1rem',
          marginLeft: '0.5rem',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: '#E57373',
            transform: 'scale(1.05)',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          },
        }}
        onClick={onSearchMaterial}
        aria-label="Media Search"
        disabled={disableSearch}
      >
        <PublicIcon sx={{ marginRight: '0.5rem', color: '#fff' }} />
        <span style={{ color: '#fff', fontSize: '0.875rem' }}>Cerca Materiale</span>
      </Box>
    </Box>
  );
};

export default ActionButtons;