// Chat.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, useMediaQuery, Button } from '@mui/material';
import Sidebar from './Sidebar';
import ChatMessages from './ChatMessages';
import MessageInput from './MessageInput';
import AgentSwitch from './agentSwitch';
import MobileHeader from './MobileHeader';
import MobileSidebar from './MobileSidebar';
import AgentStart from './AgentStart';
import ReachedLimit from './ReachedLimit';
import AgentStartMobile from './AgentStartMobile';
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  removeTokens,
} from '../../api/authUtils';
import useMediaSearch from '../../api/chat/useMediaSearch';
import { agentExamples } from './agentExamples'; // Import the examples

const baseUrl = process.env.REACT_APP_BASE_URL;

// Definition of initial messages for each agent
const initialMessages = {
  quiz: "Ciao! Sono l'Esperto di Quiz! Posso creare quiz interattivi e didattici su misura per la tua classe. Indica la classe, il livello, l'argomento e l'obiettivo specifico per iniziare a costruire insieme il quiz perfetto. ✏️",
  laboratori: "Ciao! Sono l'Esperto di Laboratori. Ideo laboratori pratici e coinvolgenti per i tuoi studenti. Specifica la classe, l'argomento e i materiali che hai a disposizione, così potrò offrirti la proposta ideale. 🔍",
  attività: "Salve! Sono l'Esperto di Attività Didattiche. Insieme possiamo creare attività innovative e su misura per i tuoi studenti. Indica la classe, l'argomento e gli obiettivi che vuoi raggiungere per ricevere suggerimenti personalizzati. 💡",
  metodologie: "Buongiorno! Sono l'Esperto di Metodologie Didattiche. Scopriamo insieme nuove metodologie educative adatte alle tue esigenze. Dimmi il contesto della tua classe e l'argomento su cui vuoi lavorare per suggerimenti mirati. 📘",
  montessori: "Benvenuto! Sono Maria Montessori. Posso guidarti nell'applicazione dei principi montessoriani. Specifica l'età dei tuoi alunni, i tuoi obiettivi e le loro necessità per consigli su misura. 🌼",
  malaguzzi: "Ciao! Sono Loris Malaguzzi. Ti aiuterò a sviluppare attività che seguono questo approccio educativo. Fornisci informazioni sugli studenti e le attività che vuoi realizzare per suggerimenti precisi e pertinenti. 🌱",
  scrittura: "Ciao! Sono l'Esperto di Scrittura di documenti scolastici. Posso aiutarti a redigere o migliorare verbali, UDA, schede didattiche e altri documenti amministrativi per la tua scuola. Dimmi di cosa hai bisogno e iniziamo a lavorare insieme! 🖋️",
  minerva: "Ciao! Sono Minerva, un’AI specializzata nel mondo educativo italiano. Conosco a fondo il sistema scolastico, le Indicazioni Nazionali e le normative del Ministero dell’Istruzione. Puoi chiedermi tutto, da informazioni sulle regole scolastiche a consigli sulle metodologie o sulla visione educativa italiana. Sono qui per aiutarti!",
  immaginai: "Ciao! Sono ImmaginAI. Posso generare immagini basate sulle tue descrizioni. Inviami un messaggio con ciò che desideri vedere, e creerò un'immagine per te! 🖼️",
};

const Chat = () => {
  // State variables
  const [chats, setChats] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [didatticaOpen, setDidatticaOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('attività');
  const [agentStartOpen, setAgentStartOpen] = useState(false);
  const [limitError, setLimitError] = useState(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  const navigate = useNavigate();
  const containerRef = useRef(null);

  // State for the mobile sidebar
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Detect if the device is mobile
  const isMobile = useMediaQuery('(max-width:600px)');

  // Import the new hook for media search
  const { searchMedia } = useMediaSearch();

  // State variable to control the display of the video
  const [showVideo, setShowVideo] = useState(false);

  // State variable to check if the user has already chatted with the agent
  const [hasChattedWithAgent, setHasChattedWithAgent] = useState(false);

  const messageInputRef = useRef(null); // Ref for the message input

  // Function to handle clicking on an example request
  const handleExampleClick = (exampleText) => {
    setNewMessage(exampleText);
    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }
  };

  // Function to update the access token
  const refreshAccessToken = async () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      removeTokens();
      navigate('/login');
      return null;
    }

    try {
      const response = await fetch(`${baseUrl}/api/accounts/token/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });

      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }

      const data = await response.json();
      setAccessToken(data.access);
      if (data.refresh) {
        setRefreshToken(data.refresh);
      }
      return data.access;
    } catch (error) {
      console.error('Error refreshing token:', error);
      removeTokens();
      navigate('/login');
      return null;
    }
  };

  // Function to make authenticated requests
  const makeAuthenticatedRequest = async (url, options = {}) => {
    let accessToken = getAccessToken();

    // If no access token, redirect to login
    if (!accessToken) {
      navigate('/login');
      return null;
    }

    // Set the Authorization header
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      let response = await fetch(url, { ...options, headers });

      // If unauthorized, try refreshing the token
      if (response.status === 401 || response.status === 403) {
        accessToken = await refreshAccessToken();
        if (!accessToken) {
          return null;
        }

        // Retry the original request with the new token
        const retryHeaders = {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`,
        };
        response = await fetch(url, { ...options, headers: retryHeaders });
      }

      return response;
    } catch (error) {
      console.error('Error making authenticated request:', error);
      return null;
    }
  };

  // Fetch chats on component mount
  useEffect(() => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      navigate('/login');
      return;
    }

    fetchChats(accessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  // Update hasChattedWithAgent when selectedOption, selectedChat, or chats change
  useEffect(() => {
    if (selectedOption && chats.length > 0) {
      const chatsWithAgent = chats.filter(
        (chat) => chat.agent === selectedOption && chat.id !== selectedChat?.id
      );
      setHasChattedWithAgent(chatsWithAgent.length > 0);
    } else {
      setHasChattedWithAgent(false);
    }
  }, [selectedOption, selectedChat, chats]);

  // Update showVideo based on messages and hasChattedWithAgent
  useEffect(() => {
    if (
      messages.length === 1 &&
      messages[0].isInitial &&
      !hasChattedWithAgent
    ) {
      setShowVideo(true);
    } else {
      setShowVideo(false);
    }
  }, [messages, hasChattedWithAgent]);

  // Auto-scroll when messages change
  useEffect(() => {
    if (shouldScroll && containerRef.current) {
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }, 100);
      setShouldScroll(false);
    } else {
      // Slight scroll to make the new user message visible
      const scrollAmount = 150;
      if (containerRef.current) {
        containerRef.current.scrollTop += scrollAmount;
      }
    }
  }, [messages, selectedChat, shouldScroll]);

  // Handle error responses from the API
  const handleErrorResponse = async (response) => {
    if (!response) return true; // Error handled (no response)

    if (response.status === 401) {
      navigate('/login');
      return true;
    }

    if (response.status === 403 || response.status === 429) {
      const errorData = await response.json();
      if (
        errorData &&
        errorData.detail &&
        errorData.detail.includes('limite')
      ) {
        setLimitError(errorData.detail);
        return true;
      } else {
        navigate('/abbonamento');
        return true;
      }
    }

    return false; // Error not handled
  };

  // Fetch chats from the API
  const fetchChats = async (token) => {
    try {
      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/chats/`,
        {
          method: 'GET',
        }
      );

      if (!response) return;

      if (response.status === 401) {
        navigate('/login');
        return;
      }

      if (response.status === 403) {
        navigate('/abbonamento');
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fetching chats:', errorData);
        return;
      }

      const data = await response.json();
      const sortedChats = data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setChats(sortedChats);

      if (sortedChats.length === 0) {
        // If the chat list is empty, open the interface to select an agent
        handleNewChat();
      } else {
        const currentChat = sortedChats.find(
          (c) => c.id === selectedChat?.id
        );
        if (currentChat && selectedChat?.id !== currentChat.id) {
          selectChat(currentChat);
        } else if (sortedChats.length > 0 && !currentChat) {
          selectChat(sortedChats[0]);
        }
      }
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  // Fetch messages for a chat and a specific agent
  const fetchMessages = async (chatId, agent) => {
    setLoading(true);
    try {
      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/chats/${chatId}/messages/${agent}/?include=messages`,
        {
          method: 'GET',
        }
      );

      if (!response) {
        setLoading(false);
        return;
      }

      if (response.status === 401 || response.status === 403) {
        navigate('/login');
        setLoading(false);
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fetching messages:', errorData);
        setLoading(false);
        return;
      }

      const data = await response.json();
      let fetchedMessages = data.messages;

      // Check if the initial message is already present
      const initialMessageText = initialMessages[agent] || 'Benvenuto!';
      const hasInitialMessage = fetchedMessages.some(
        (msg) =>
          msg.text === initialMessageText && msg.sender === 'assistant'
      );
      // If there are no messages, add the initial message
      if (fetchedMessages.length === 0 || !hasInitialMessage) {
        const initialMessage = {
          id: `initial-${chatId}`, // Unique ID for each chat
          chat_id: chatId,
          text: initialMessageText,
          sender: 'assistant',
          isInitial: true, // Add the isInitial flag
        };
        fetchedMessages = [initialMessage, ...fetchedMessages];
      }

      setMessages(fetchedMessages);
      setShouldScroll(true);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setLoading(false);
    }
  };

  // Create a new chat with a specific agent
  const handleNewChat = async (
    agentOption = selectedOption,
    fromAgentStart = false
  ) => {
    setSelectedOption(agentOption); // Update the selected option

    if (!fromAgentStart && agentOption === selectedOption) {
      setAgentStartOpen(true);
      return;
    }

    const accessToken = getAccessToken();
    if (!accessToken) return;

    try {
      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/chats/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ agent: agentOption }),
        }
      );

      const errorHandled = await handleErrorResponse(response);
      if (errorHandled) return;

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error creating new chat:', errorData);
        return;
      }

      const data = await response.json();
      setChats([data, ...chats]);
      selectChat(data);
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  // Delete a specific chat
  const handleDeleteChat = async (chatId) => {
    const accessToken = getAccessToken();
    if (!accessToken) return;

    try {
      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/chats/${chatId}/delete/`,
        {
          method: 'DELETE',
        }
      );

      if (!response) return;

      if (response.status === 401 || response.status === 403) {
        navigate('/login');
        return;
      }

      if (response.ok) {
        const updatedChats = chats.filter((chat) => chat.id !== chatId);
        setChats(updatedChats);
        if (updatedChats.length > 0) {
          selectChat(updatedChats[0]);
        } else {
          setSelectedChat(null);
          setMessages([]);
        }
      } else {
        console.error('Error deleting chat');
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const handleGenerateImage = async () => {
    if (!newMessage.trim()) return;

    const accessToken = getAccessToken();
    if (!accessToken) {
      console.error('No access token found, redirecting to login...');
      navigate('/login');
      return;
    }

    // Add a user message to the chat
    const userMessage = {
      id: Date.now(),
      chat_id: selectedChat.id,
      text: newMessage,
      sender: 'user',
    };

    setMessages((prevMessages) => [
      ...prevMessages,
      userMessage,
      {
        id: Date.now() + 1,
        chat_id: selectedChat.id,
        text: '',
        sender: 'assistant',
        isTyping: true,
      },
    ]);
    setNewMessage('');

    try {
      // Make the request to the generate_image_view endpoint
      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/generate-image/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: newMessage,
            chat_id: selectedChat.id,
          }),
        }
      );

      // Handle any errors in the response
      const errorHandled = await handleErrorResponse(response);
      if (errorHandled) {
        setMessages((prevMessages) => prevMessages.slice(0, -1));
        return;
      }

      // Check if the response is not OK
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error generating image:', errorData);
        setMessages((prevMessages) => prevMessages.slice(0, -1));
        return;
      }

      // Process the response
      const data = await response.json();
      const assistantMessage = data.assistant_message;

      // Update the assistant's message with the generated image
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        if (lastMessage && lastMessage.sender === 'assistant') {
          return [
            ...prevMessages.slice(0, -1),
            {
              ...assistantMessage,
              sender: 'assistant',
              isTyping: false,
            },
          ];
        } else {
          return prevMessages;
        }
      });

      // Update the chats to reflect any changes
      await fetchChats(accessToken);
    } catch (error) {
      console.error('Error generating image:', error);
      setMessages((prevMessages) => prevMessages.slice(0, -1));
    }
  };

  // Send a new message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() && files.length === 0) return;
    if (!selectedChat) return;

    // Specific case for the "immaginai" agent
    if (selectedOption === 'immaginai') {
      await handleGenerateImage(); // Call the function to generate images
      return; // Exit the function to avoid executing the logic for other agents
    }

    const accessToken = getAccessToken();
    if (!accessToken) return;

    const userMessage = {
      id: Date.now(),
      chat_id: selectedChat.id,
      text: newMessage,
      sender: 'user',
      attachments: files.map((file) => ({
        file_name: file.name,
      })),
    };

    // Add the user's message and a placeholder for the assistant
    setMessages((prevMessages) => [
      ...prevMessages,
      userMessage,
      {
        id: Date.now() + 1,
        chat_id: selectedChat.id,
        text: '',
        sender: 'assistant',
        isTyping: true,
      },
    ]);
    setNewMessage('');
    setFiles([]);

    try {
      const formData = new FormData();
      formData.append('text', newMessage);

      files.forEach((file) => {
        if (file.blob) {
          formData.append('files', file.blob, file.name);
        } else {
          formData.append('files', file);
        }
      });

      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/chats/${selectedChat.id}/messages/${selectedOption}/`,
        {
          method: 'POST',
          body: formData,
        }
      );

      const errorHandled = await handleErrorResponse(response);
      if (errorHandled) {
        setMessages((prevMessages) => prevMessages.slice(0, -1)); // Remove the assistant's placeholder
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error sending message:', errorData);
        setMessages((prevMessages) => prevMessages.slice(0, -1));
        return;
      }

      const data = await response.json();
      let assistantMessage;

      if (data.assistant_message) {
        assistantMessage = data.assistant_message;
      } else if (data.message) {
        assistantMessage = {
          id: Date.now() + 1,
          chat_id: selectedChat.id,
          text: data.message,
          sender: 'assistant',
          attachments: [],
        };
      } else {
        console.error(
          "La risposta dell'API non contiene un messaggio valido:",
          data
        );
        setMessages((prevMessages) => prevMessages.slice(0, -1));
        return;
      }

      // Update the assistant's message
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        if (lastMessage && lastMessage.sender === 'assistant') {
          return [
            ...prevMessages.slice(0, -1),
            {
              ...lastMessage,
              ...assistantMessage,
              isTyping: false,
            },
          ];
        } else {
          return prevMessages;
        }
      });

      await fetchChats(accessToken);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prevMessages) => prevMessages.slice(0, -1));
    }
  };

  // Handle media search for a message
  const handleMediaSearch = async (message, mediaType) => {
    try {
      // Set the media loading state
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id ? { ...msg, mediaLoading: true } : msg
        )
      );

      // Perform media search using the hook
      const mediaData = await searchMedia(message.text, mediaType);

      // Get the media array based on the selected type
      const mediaArray = mediaData[`${mediaType}s`] || mediaData;

      // Update the message with the received media
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id
            ? { ...msg, media: mediaArray, mediaLoading: false }
            : msg
        )
      );
    } catch (error) {
      console.error('Errore durante la ricerca dei media:', error);
      // Reset the loading state in case of error
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id ? { ...msg, mediaLoading: false } : msg
        )
      );
    }
  };

  // Handle option (agent) change
  const handleOptionChange = async (option) => {
    setSelectedOption(option);
    setDidatticaOpen(false);
    await handleNewChat(option);
  };

  // Select a specific chat
  const selectChat = (chat) => {
    if (selectedChat?.id !== chat.id) {
      setSelectedChat(chat);
      setSelectedOption(chat.agent); // Update the selected option based on the chat's agent
    }
  };

  // Fetch messages when the selected chat or agent changes
  useEffect(() => {
    if (selectedChat) {
      fetchMessages(selectedChat.id, selectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChat, selectedOption]);

  // Handle user logout
  const handleLogout = () => {
    removeTokens();
    navigate('/login');
  };

  const handleCloseReachedLimit = () => {
    setLimitError(null);
  };

  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      );
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (limitError) {
    return (
      <ReachedLimit
        errorMessage={limitError}
        onClose={handleCloseReachedLimit}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        height: 'calc(var(--vh, 1vh) * 100)',
        backgroundColor: '#F3F6FB',
        fontFamily: 'Inter, sans-serif',
        position: 'relative',
      }}
    >
      {isMobile ? (
        <>
          <MobileHeader
            didatticaOpen={didatticaOpen}
            setDidatticaOpen={setDidatticaOpen}
            selectedOption={selectedOption}
            handleOptionChange={handleOptionChange}
            toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
            handleNewChat={handleNewChat}
          />
          {sidebarOpen && (
            <MobileSidebar
              chats={chats}
              selectedChat={selectedChat}
              selectChat={selectChat}
              handleNewChat={handleNewChat}
              handleDeleteChat={handleDeleteChat}
              handleLogout={handleLogout}
              navigate={navigate}
              selectedOption={selectedOption}
              onClose={() => setSidebarOpen(false)}
            />
          )}
        </>
      ) : (
        <>
          <Sidebar
            chats={chats}
            selectedChat={selectedChat}
            selectChat={selectChat}
            handleNewChat={handleNewChat}
            handleDeleteChat={handleDeleteChat}
            handleLogout={handleLogout}
            navigate={navigate}
            selectedOption={selectedOption}
          />

          {selectedOption === 'montessori' ||
          selectedOption === 'malaguzzi' ? (
            <Box
              sx={{
                position: 'fixed',
                left: '20%',
                top: '2.5rem',
                display: 'flex',
                alignItems: 'center',
                zIndex: 2000,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#DF4634',
                  color: '#FFFFFF',
                  borderRadius: '1.875rem',
                  width: '7rem',
                  height: '2.2rem',
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  '&:hover': {
                    backgroundColor: '#E57373',
                  },
                }}
              >
                {selectedOption.charAt(0).toUpperCase() +
                  selectedOption.slice(1)}
              </Button>
            </Box>
          ) : (
            <AgentSwitch
              didatticaOpen={didatticaOpen}
              setDidatticaOpen={setDidatticaOpen}
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
            />
          )}
        </>
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: isMobile ? '1rem' : '3.125rem',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <ChatMessages
          messages={messages}
          loading={loading}
          containerRef={containerRef}
          chatTitle={selectedChat?.title || 'Chat'}
          selectedOption={selectedOption}
          handleMediaSearch={handleMediaSearch}
          showVideo={showVideo}
          agentExamples={agentExamples[selectedOption]} // Pass examples to ChatMessages
          onExampleClick={handleExampleClick} // Pass the click handler
        />
        <MessageInput
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          handleSendMessage={handleSendMessage}
          files={files}
          setFiles={setFiles}
          inputRef={messageInputRef} // Pass the ref to MessageInput
        />

        {/* Overlay when the AgentSwitch submenu is active */}
        {didatticaOpen && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 1500,
              pointerEvents: 'none',
            }}
          />
        )}

        {/* Overlay when AgentStart is active */}
        {agentStartOpen &&
          (isMobile ? (
            <AgentStartMobile
              open={agentStartOpen}
              onClose={() => setAgentStartOpen(false)}
              handleNewChat={(agentKey) => {
                setAgentStartOpen(false);
                handleNewChat(agentKey, true);
              }}
            />
          ) : (
            <AgentStart
              open={agentStartOpen}
              onClose={() => setAgentStartOpen(false)}
              handleNewChat={(agentKey) => {
                setAgentStartOpen(false);
                handleNewChat(agentKey, true);
              }}
            />
          ))}
      </Box>
    </Box>
  );
};

export default Chat;