import React from 'react';
import './styles/AccessDeniedMessage.css'; // Creiamo un nuovo file CSS per questo componente

const AccessDeniedMessage = ({ onClose }) => {
  return (
    <div className="accessDeniedContainer">
      <div className="accessDeniedContent">
        <h2>Accesso Risorsa Premium Negato</h2>
        <p>
          Questa risorsa è accessibile solo agli utenti con un abbonamento mensile o annuale. 
          <br />
          <strong>Passa a un abbonamento premium</strong> per accedere a contenuti esclusivi.
        </p>
        <button className="upgradeButton" onClick={onClose}>
          Chiudi
        </button>
      </div>
    </div>
  );
};

export default AccessDeniedMessage;