// src/components/VideoComponent.js
import React from 'react';
import PropTypes from 'prop-types';
import './styles/VideoComponent.css';

const VideoComponent = ({ videoSrc, style }) => {
  return (
    <div className="video-container" style={style}>
      <video
        className="background-video"
        src={videoSrc}
        autoPlay
        loop
        muted
        playsInline
      />
    </div>
  );
};

VideoComponent.propTypes = {
  videoSrc: PropTypes.string.isRequired,
};

export default VideoComponent;

